import { memo } from 'react'
import type { FC } from 'react'
import { css } from 'styled-components'
import { useModal } from '@dy/commons/hooks'
import { MODALS } from '@dy/commons/context'
import { Modal } from '.'
import { getP18_1, mq, vw } from '@dy/commons/styles'
import { getDynamicText } from '@dy/commons/utils'
import { useTranslator } from '@/hooks'
import { AddToCart } from 'components/cart'
type MenuProps = {}

export const ModalBuyBox: FC<MenuProps> = memo(() => {
  const { isActive, toggle, payload } = useModal(MODALS.BUY_BOX)
  const { t } = useTranslator()
  const data = {...payload?.data }

  return isActive && (
    <Modal isActive={isActive} close={toggle} title={t('modals.buy_box.title') as string}  xAlign='flex-end' size='s' dialogStyles={dialogStyles} hasHeader={true} >
      <p>{getDynamicText(t('modals.buy_box.text') as string, {
        units: data.variant.qtyPerBox
      })}
      </p>
      <div>
        <AddToCart data={data} inventoryTracking={payload.inventoryTracking} isPDP={true} isBox={true}/>
      </div>
    </Modal>
  )
})

const dialogStyles = css<any>`
  .modal__body {
    padding: ${vw(30, 'mobile')} ${vw(40 - 4.25, 'mobile')} ${vw(25, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      padding: ${vw(30, 'nexus7')} ${vw(40 - 4.25, 'nexus7')} ${vw(25, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      padding: ${vw(30, 'desktop')} ${vw(40 - 12.5, 'desktop')} ${vw(25, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      padding: 30px 27.5px 25px;
    }

    p {
      ${getP18_1}
      color: ${({ theme }) => theme.colors.darkred};
      margin: 0 auto 5px;
      text-align: center;
      width: 80%
    }


    > div {
      display: flex;
      justify-content: center;
      margin: 30px auto;
      width: 60%;
    }
  }


`
