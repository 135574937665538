import { gql } from '@apollo/client'

type TypeGraphQLPageInfo = {
  endCursor: string,
  startCursor: string,
  hasNextPage: boolean,
  hasPreviousPage: boolean
}

type TypeTempProduct = {
  sku: string,
  name: string
}

export interface iQueryProducts {
  productsQuery: TypeTempProduct[]
  pageInfo: TypeGraphQLPageInfo
}

export const GET_PRODUCTS_STATIC_PATHS = gql`
  query GET_PRODUCTS_STATIC_PATHS {
    products{
      slug
    }
  }
`

export const FRAGMENT_PRICE = gql`
  fragment FragmentPrice on Variant {
    price {
      discount
      salePrice:discountedPrice
      retailPrice:fullPrice
    }
  }
`

export const FRAGMENT_VARIANT_REQUIRED = gql`
  fragment FragmentVariantRequired on Variant {
    id
    bigcommerceId
    ean
    gtin
    deliveryDate
    deliveryType
    onDemandDay
    media {
      mediaType
      medium(variant: "pdp")
    }
    minSaleUnits
    qtyPerBox
    name
    ...FragmentPrice
    sku
    stock
    stockWarning
  }
  ${FRAGMENT_PRICE}
`

export const FRAGMENT_PRODUCT_REQUIRED = gql`
  fragment FragmentProductRequired on Product {
    bigcommerceId
    brand {
      name
    }
    ean
    gtin
    inventoryTracking
    name
    productType
    slug
    sku
  }
`

export const FRAGMENT_DIGITAL_PRODUCT_REQUIRED = gql`
  fragment FragmentDigitalProduct on DigitalProduct {
    name
    price
    sku
  }
`


const FRAGMENT_RELATED_VARIANTS = gql`
  fragment FragmentRelatedVariants on Product {
    related {
      ...FragmentVariantRequired
      product {
        ...FragmentProductRequired
        labels {
          name
        }
        brand {
          name
          slug
        }
        description
        ingredients
        nutrition {
          name
          value
          nutritionType
        }
        infoSpecifications {
          name
          value
        }
      }
    }
  }
  ${FRAGMENT_VARIANT_REQUIRED}
  ${FRAGMENT_PRODUCT_REQUIRED}
`

export const GET_PRODUCT_CMS = gql`
  query GET_PRODUCT_CMS($slug: String!) {
    product(slug: $slug) {
      ...FragmentProductRequired
      seo
      description
      infoSpecifications {
        name
        value
      }
      categories {
        name
      }
      ingredients
      ...FragmentRelatedVariants
      nutrition {
        name
        value
        nutritionType
      }
      labels {
        name
      }
      brand {
        name
        slug
      }
      variants {
        ...FragmentVariantRequired
        product {
          ...FragmentProductRequired
          labels {
            name
          }
          brand {
            name
            slug
          }
        }
      }
      filters {
        name
        param
        type: filterType
        values {
          value
          description
          visible
          image
        }
      }
    }
  }
  ${FRAGMENT_PRODUCT_REQUIRED}
  ${FRAGMENT_VARIANT_REQUIRED}
  ${FRAGMENT_RELATED_VARIANTS}
`

export const GET_SEARCH_PRODUCTS = gql`
  query GET_SEARCH_PRODUCTS($search: String!) {
    productSearch(search: $search) {
      count
      categories {
        name
        slug
        bigcommerceId
        subcategories {
          name
          slug
        }
      }
      brands {
        name
        logo
        slug
      }
      variants {
        edges {
          cursor
          node {
            ...FragmentVariantRequired
            product {
              ...FragmentProductRequired
              description
              ingredients
            }
          }
        }
      }
    }
  }
  ${FRAGMENT_PRODUCT_REQUIRED}
  ${FRAGMENT_VARIANT_REQUIRED}
`

export const GET_BRANDS = gql`
  query GET_BRANDS {
    brands {
      slug
    }
  }
`

export const GET_BRAND = gql`
  query GET_BRAND($slug: String!) {
    brand(slug: $slug) {
      description
      logo
      name
      slug
      categories {
        name
        slug
        subcategories {
          name
          slug
        }
      }
      variants {
        edges {
          cursor
          node {
            ...FragmentVariantRequired
             packItems {
              name
              sku
            }
            product {
              description
              ean
              gtin
              inventoryTracking
              ingredients
              name
              productType
              seo
              sku
              slug
              brand {
                name
                slug
              }
            }
          }
        }
      }
    }
  }
  ${FRAGMENT_VARIANT_REQUIRED}
`

export const GET_BRAND_CATEGORIES = gql`
  query GET_BRAND_CATEGORIES($slug: String!) {
    brand(slug: $slug) {
      categories {
        slug
        subcategories {
          slug
        }
      }
    }
  }
`

export const GET_BRAND_CATEGORY = gql`
  query GET_BRAND_CATEGORY($slug: String!, $categorySlug: String! ) {
    brand(slug: $slug) {
      description
      logo
      name
      slug
      categories {
        name
        slug
        subcategories {
          name
          slug
        }
      }
      category(slug: $categorySlug) {
        name
        slug
        subcategories {
          name
          slug
        }
      }
      variants(categorySlug:  $categorySlug) {
        edges {
          cursor
          node {
            ...FragmentVariantRequired
            product {
              description
              ean
              gtin
              ingredients
              name
              productType
              seo
              sku
              slug
            }
          }
        }
      }
    }
  }
  ${FRAGMENT_VARIANT_REQUIRED}
`
