import { memo, useMemo, useState } from 'react'
import { css } from 'styled-components'
import type { FC } from 'react'
import { useRouter } from 'next/router'
import { motion, AnimatePresence } from 'framer-motion'
import { useInView } from '@dy/commons/hooks'
import { AddToCart } from '@/components'
import { getLang, currencyFormatter } from '@dy/commons/utils'
import { Price, Figure, ToolTip } from '@dy/commons/components'
import styled from 'styled-components'
import { mq, vw, hex2Rgba, getP15_1, getP15, getP12_1, getTransition, getP10_1 } from '@dy/commons/styles'
import type { TypeVariant } from '@dy/commons/schema'
import { useActiveVariant, useCustomer, useTranslator } from '@/hooks'

type IProductCard = {
  data: TypeVariant
  narrow?: boolean,
  onClick?: any,
  isLazy?: boolean,
  pricePer?: string,
  lastUnits?: string,
  round?: boolean,
  readonly inSlider?: boolean,
}

export const getPricePerUnit = (reference_price, reference_unit, round, locale) => {
  return reference_price && reference_unit ? ` • ${currencyFormatter({price:reference_price, localeRegion:locale, round})}/${reference_unit}` : null
}

export const FILTER_LABELS = {
  'sin-gluten': '/images/svg/label-sin-gluten.svg',
  'bio': '/images/svg/label-bio.svg',
  'fresh': '/images/svg/label-fresh.svg',
  'integral': '/images/svg/label-integral.svg',
  'vegano': '/images/svg/label-vegan.svg'
}

export const getFilterLabels = (filters:any, pathname:string, showTooltip?:boolean, setShowTooltip?:any) => filters.map((filter, idx) => {
  const isPDP = (pathname === '/catalog/product/[slug]') || (pathname === '/shop/product/[slug]')
  return (
    <figure key={idx} {...(filter.param === 'fresh' && { onMouseEnter: () => { setShowTooltip(true) }, onMouseLeave: () => { setShowTooltip(false) } })} className={`filter-label ${filter.param === 'fresh' ? 'fresh' : ''}`}>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img src={FILTER_LABELS[filter.param]} alt='Filter label image' />
      {(filter.param === 'fresh' && isPDP) ? <ToolTip showTooltip={showTooltip} /> : <></>}
    </figure>
  )})


const productCardSizes = '(min-width: 768px) 30vw, 50vw'

const transformLabels = labels => labels.map(label => label.name)

const getCMSLabels = labels => labels.map((label, idx) => {
  return <span key={idx} className='cms'><span>{label}</span></span>
})


export const ProductCard: FC<IProductCard> = memo(({ data, narrow = false, onClick = null, isLazy = true, pricePer, round = false, lastUnits }) => {
  const { product } = data
  const { locale:localeRouter, pathname } = useRouter()
  const locale = getLang(localeRouter)
  const { activeVariant, cardSelected } = useActiveVariant()
  const { customer } = useCustomer()
  const { t } = useTranslator()
  const linkPathname = pathname.includes('account') || pathname.includes('page') || pathname.includes('welcome') || pathname.includes('cart') ? 'shop' : pathname === '/'?  'catalog' : pathname.split('/')[1]
  //dev HARDCODED
  const [showTooltip, setShowTooltip] = useState(false)
  const { bigcommerceId: variant_bigcommerce_id, media, name:variantName, price, stock = 0, stockWarning, minSaleUnits, variantType = 'regular', deliveryType } = data || {}
  const { name, brand, slug, categories, ingredients, labels, filters, inventoryTracking } = product || {}
  const [hasUnitsInCart, setHasUnitsInCart] = useState(false)
  const isFrozenProduct = useMemo(() => deliveryType === 'frozen', [deliveryType])
  // @ts-ignore
  const { discount } = price || {}
  const FormattedLabels = useMemo(() => labels ? transformLabels(labels) : [], [labels])
  const labelsAsTags = labels?.length > 1 || FormattedLabels?.[0]?.length > 2
  const LabelCMS = useMemo(() => labelsAsTags ? getCMSLabels(FormattedLabels) : <></>, [labelsAsTags, FormattedLabels])
  const LabelDiscount = useMemo(() => discount > 0 ? <span className='discount'>-{discount}%</span> : <></>, [discount])
  const PreselectFilters = useMemo(() => filters?.filter(item => FILTER_LABELS[item.param]), [filters])
  const LabelFilters = useMemo(() => PreselectFilters?.length > 0 ? getFilterLabels(PreselectFilters, pathname, showTooltip, setShowTooltip) : <></>, [PreselectFilters, pathname, showTooltip, setShowTooltip])
  const emptyCard = Object.keys(data).length === 0

  const { ref } = useInView({
    rootMargin: '0px',
    threshold: 0,
    triggerOnce: true,
  })

  return (
    <Article data-sku={data.sku} ref={ref} className={`product-card${variantType === 'pack' ? ' pack' : ''}${cardSelected?.variant_id === variant_bigcommerce_id ? ' selected' : ''}${hasUnitsInCart ? ' active' : ''}${customer && inventoryTracking && stock < minSaleUnits ? ' no-stock' : (price === null && stock > 0) ? ' not-available' : ''}${false ? 'pdp-displayed' : ''}`} $pack={variantType === 'pack'} $narrow={narrow} $frozen={isFrozenProduct}>
      <AnimatePresence>
        <motion.div variants={variants} initial='on_exit' animate='on_load' exit='on_exit'>
          {!emptyCard &&
            <>
              <StyledLink href={`/${linkPathname}/product/${slug?.[locale] ?? ''}`} onClick={onClick} aria-label='show product'>
                <LabelWrapper filterLabelCount={PreselectFilters?.length} hasDiscount={discount > 0} className={`${!!activeVariant ? 'narrow-card' : 'wide-card'}`} >
                  <div className='right'>
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    {isFrozenProduct && <span className='filter-label'><figure><img src='/images/svg/label-fresh.svg' alt='frozen product'></img></figure></span>}
                    {LabelDiscount}
                    {LabelFilters}
                  </div>
                  {LabelCMS}
                </LabelWrapper>
                {media?.[0]?.medium ?
                  <Figure key={`card-img-${variant_bigcommerce_id}`} lazy={isLazy} className={`product-card-image ${isFrozenProduct ? 'frozen_product' : ''}`}  media={media?.[0].medium} sizes={productCardSizes} quality={99} />
                  :
                  <figure className='product-card-image'>
                    <div className='no-img'></div>
                  </figure>
                }
                <ProductInfo className='product-card_info' $frozen={isFrozenProduct}>
                  <span>{name}</span>
                  {(typeof brand === 'object') ?
                    <span>{brand?.name} </span>
                    :
                    <>{ingredients && <span>{ingredients}</span>}</>
                  }
                  <>
                    {minSaleUnits > 1 ?
                      <span>{variantName ? variantName + ' ' +  'x': ' ' } {minSaleUnits} {t('product_page.product.units_abbr')}</span>
                      :
                      <span>{variantName}</span>
                    }
                  </>

                </ProductInfo>
                {price !== null && <Price type='card' price={price} className={'product__price'} pricePer={pricePer} round={round} stockWarning={stockWarning} stock={stock} lastUnits={lastUnits} minSaleUnits={minSaleUnits}/>}
              </StyledLink>
              <AddToCart data={{ name, brand, categories, variant: data }} isPDP={false} setHasUnitsInCart={setHasUnitsInCart} notAvailable={price === null && stock > 0} inventoryTracking={inventoryTracking} frozen={isFrozenProduct} />
            </>
          }
        </motion.div>
      </AnimatePresence>
    </Article>
  )
})

const StyledLink = styled.a`
  align-items: flex-start;
  display:flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  width: 100%;
`

const Article = styled.article<{ $narrow: boolean, $pack: boolean, $frozen: boolean }>`
  background-color: ${({ theme, $frozen }) => $frozen ? '#e1f2ff':theme.colors.white};
  border-radius: 20px;
  border: 2px solid ${({ theme }) => theme.colors.background};
  font-family: ${({ theme }) => theme.fonts.secondaryFont};
  height: ${vw(277, 'mobile')};
  margin-right: ${vw(5, 'mobile')};
  overflow: hidden;
  position: relative;
  transition: 200ms border-color linear;
  width: ${vw(175, 'mobile')};

  ${mq.greaterThan('nexus7')} {
    height: ${vw(277, 'nexus7')};
    margin-right: ${vw(5, 'nexus7')};
    width: ${vw(175, 'nexus7')};

    &.selected {
      border-color: ${({ theme, $frozen }) => $frozen ? 'rgb(122, 134, 238)' : hex2Rgba(theme.colors.red, .6)};
    }

    &:hover {
      border-color: ${({ theme, $frozen }) => $frozen ? 'rgb(146, 156, 254)' : hex2Rgba(theme.colors.darkcreme, .6)};
    }
  }

  ${mq.greaterThan('tablet')} {
    height: ${vw(360, 'desktop')};
    margin-right: ${vw(5, 'desktop')};
    width: ${({ $narrow }) => $narrow ? `${vw(204, 'desktop')}` : `${vw(261, 'desktop')}`};

  }

  ${mq.greaterThan('desktop')} {
    height: 360px;
    margin-right: 5px;
    width: ${({ $narrow }) => $narrow ? '204px' : '261px'};
  }

  &.active,
  &:hover {
    button:first-of-type:not(button[disabled]) {
      opacity: 1;
    }
  }

  &.active {
    .btn-add {
      ${mq.greaterThan('tablet')} {
        opacity: 1;
      }

      &.insufficient-stock {
        opacity: .4;

        ${mq.greaterThan('tablet')} {
          opacity: .4 !important;
        }
      }
    }
  }

  &.no-stock,
  &.not-available {
    &:hover {
      .btn-add {
        opacity: .4 !important;
        pointer-events: none;
        user-select: none;

        ${mq.greaterThan('tablet')} {
          opacity: .4 !important;
        }
      }
    }

    .insufficient-stock:not(.selected-card) {
      &[disabled] {
        height: ${vw(25, 'mobile')};
        margin-right: 0;
        pointer-events: none;
        width: ${vw(80, 'mobile')};

        ${mq.greaterThan('nexus7')} {
          height: ${vw(25, 'nexus7')};
          pointer-events: none;
          width: ${vw(80, 'nexus7')};
        }

        ${mq.greaterThan('tablet')} {
          height: ${vw(25, 'desktop')};
          pointer-events: none;
          width: ${vw(80, 'desktop')};
        }

        ${mq.greaterThan('desktop')} {
          height: 25px;
          width: 80px;
        }

        > div:last-of-type {
          ${getP10_1()}
        }
      }
    }
  }

  > div {
    height: 100%;
  }

  .frozen_product {
    span {
      background-color: #e1f2ff !important;
    }

    img {
      mix-blend-mode: multiply;
    }
  }

  .product-card-image {
    height: ${vw(152, 'mobile')};
    margin: 0 auto;
    overflow: hidden;

    ${mq.greaterThan('nexus7')} {
      height: ${vw(144, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      height: ${vw(200, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      height: 200px;
    }

    ${({ $pack, $narrow }) => $pack ? css`
      padding: 0;
      width: 100%;
    ` : css`
      width: ${vw(147, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        width: ${vw(154, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        width: ${vw($narrow ? 175 : 220, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        width: ${$narrow ? 175 : 220}px;
      }
    `}

    img {
      height: 100%;
      object-fit: ${({ $pack }) => $pack ? 'cover' : 'contain'};
      border-radius: 20px;

      ${mq.greaterThan('nexus7')} {
        object-fit: contain;
      }

      ${mq.greaterThan('tablet')} {
        object-fit: ${({ $pack }) => $pack ? 'cover' : 'contain'};
      }
    }

    .no-img {
      background-color: #F7F7F7;
      border: ${vw(10, 'mobile')} solid ${({ theme, $frozen }) =>  $frozen ? '#e1f2ff' : theme.colors.white};
      height: 100%;
      margin: 0 auto;
      width: 100%;

      ${mq.greaterThan('nexus7')} {
        border-width: ${vw(10, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        border-width: ${vw(15, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        border-width: 15px;
      }
    }
  }
`

const ProductInfo = styled.div<any>`
  display: flex;
  flex-direction: column;
  padding: ${vw(7, 'mobile')} ${vw(10, 'mobile')} 0;
  width: inherit;

  ${mq.greaterThan('nexus7')} {
    padding: ${vw(7, 'nexus7')} ${vw(10, 'nexus7')} 0;
  }

  ${mq.greaterThan('tablet')} {
    padding: ${vw(9, 'desktop')} ${vw(15, 'desktop')} 0;
  }

  ${mq.greaterThan('desktop')} {
    padding: 9px 15px 0;
  }

  span {
    ${getP15_1()}
    color: ${({ theme, $frozen }) => $frozen ? '#616fc4' : theme.colors.red};
    display: inline-block;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:first-of-type {
      ${getP15()}
      color: ${({ theme, $frozen }) => $frozen ? '#11294e' : theme.colors.darkred};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      ${mq.greaterThan('tablet')} {
        margin-bottom: ${vw(2, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        margin-bottom: 2px;
      }
    }
  }


  + div {
    width: 100%;
    margin-top:auto;

  }
`

const LabelWrapper = styled.div<any>`
  position: absolute;
  overflow: hidden;
  z-index: 10;
  top: ${vw(20, 'mobile')};
  right: ${vw(15, 'mobile')};
  left: ${vw(15, 'mobile')};
  min-height: ${vw(25, 'mobile')};

  ${mq.greaterThan('nexus7')} {
    top: ${vw(20, 'nexus7')};
    right: ${vw(15, 'nexus7')};
    left: ${vw(15, 'nexus7')};
    min-height: ${vw(25, 'nexus7')};
  }

  ${mq.greaterThan('tablet')} {
    top: ${vw(20, 'desktop')};
    right: ${vw(15, 'desktop')};
    left: ${vw(15, 'desktop')};
    min-height: ${vw(25, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    top: 20px;
    right: 15px;
    left: 15px;
    min-height: 25px;
  }

  .right {
    display: flex;
    float: right;
    column-gap: ${vw(5, 'mobile')};
    row-gap: ${vw(5, 'mobile')};
    margin-bottom: ${vw(5, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      column-gap: ${vw(5, 'nexus7')};
      row-gap: ${vw(5, 'nexus7')};
      margin-bottom: ${vw(5, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      column-gap: ${vw(5, 'desktop')};
      row-gap: ${vw(5, 'desktop')};
      margin-bottom: ${vw(5, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      column-gap: 5px;
      row-gap: 5px;
      margin-bottom: 5px;
    }

    > * {
      display: flex;
      column-gap: ${vw(5, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        column-gap: ${vw(5, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        column-gap: ${vw(5, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        column-gap: 5px;
      }

      figure {
        height: ${vw(24, 'mobile')};
        width: ${vw(24, 'mobile')};

        ${mq.greaterThan('nexus7')} {
          height: ${vw(24, 'nexus7')};
          width: ${vw(24, 'nexus7')};
        }

        ${mq.greaterThan('tablet')} {
          height: ${vw(24, 'desktop')};
          width: ${vw(24, 'desktop')};
        }


        ${mq.greaterThan('desktop')} {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  .cms {
    display: inline-block;
    max-width: ${vw(60, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      max-width: ${vw(60, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      max-width: ${vw(60, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      max-width: 60px;
    }
  }

  .cms > span,
  .discount {
    ${getP12_1()}
    color: ${({ theme }) => theme.colors.white};
    text-transform: uppercase;
    border-radius: ${vw(12, 'mobile')};
    padding: ${vw(5, 'mobile')} ${vw(9, 'mobile')};
    max-height: ${vw(25, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      border-radius: ${vw(12, 'nexus7')};
      padding: ${vw(5, 'nexus7')} ${vw(9, 'nexus7')};
      max-height: ${vw(25, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      border-radius: ${vw(12, 'desktop')};
      padding: ${vw(5, 'desktop')} ${vw(9, 'desktop')};
      max-height: ${vw(25, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      border-radius: 12px;
      padding: 5px 9px;
      max-height: 25px;
    }
  }

  .cms > span {
    background-color: ${({ theme }) => theme.colors.darkcreme};
    float: left;
    margin-right: ${vw(5, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      margin-right: ${vw(5, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      margin-right: ${vw(5, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-right: 5px;
    }
  }

  .cms:first-of-type > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.narrow-card .cms:first-of-type > span {
    ${mq.greaterThan('tablet')} {
      max-width: ${({ filterLabelCount, hasDiscount }) => filterLabelCount === 1 ? hasDiscount ? vw(75, 'desktop') : vw(120, 'desktop') : filterLabelCount === 2 ? hasDiscount ? vw(47, 'desktop') : vw(90, 'desktop') : filterLabelCount === 3 && !hasDiscount ? vw(65, 'desktop') : filterLabelCount === 4 && !hasDiscount ? vw(35, 'desktop') :  vw(120, 'desktop')};
      margin-right: ${vw(5, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      max-width: ${({ filterLabelCount, hasDiscount }) => filterLabelCount === 1 ? hasDiscount ? '75px' : '120px' : filterLabelCount === 2 ? hasDiscount ? '47px' : '90px' : filterLabelCount === 3 && !hasDiscount ? '65px' : filterLabelCount === 4 && !hasDiscount ? '35px' : '120px'};
      margin-right: 5px;
    }

    ${mq.greaterThan('desktop-wide')} {
      max-width: ${({ filterLabelCount, hasDiscount }) => filterLabelCount === 1 ? hasDiscount ? '65px' : '120px' : filterLabelCount === 2 ? hasDiscount ? '47px' : '90px' : filterLabelCount === 3 && !hasDiscount ? '65px' : filterLabelCount === 4 && !hasDiscount ? '35px' : '120px'};
      margin-right: 5px;
    }
  }

  &.wide-card .cms:first-of-type > span {
    transition: 300ms max-width ${({ ease }) => ease};
    max-width: ${({ filterLabelCount, hasDiscount }) => filterLabelCount === 1 ? hasDiscount ? vw(75, 'mobile') : vw(115, 'mobile') : filterLabelCount === 2 ? hasDiscount ? vw(47, 'mobile') : vw(87, 'mobile') : vw(140, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      max-width: ${({ filterLabelCount, hasDiscount }) => filterLabelCount === 1 ? hasDiscount ? vw(75, 'nexus7') : vw(115, 'nexus7') : filterLabelCount === 2 ? hasDiscount ? vw(47, 'nexus7') : vw(87, 'nexus7') : vw(140, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      max-width: ${({ filterLabelCount, hasDiscount }) => filterLabelCount === 1 ? hasDiscount ? vw(125, 'desktop') : vw(175, 'desktop') : filterLabelCount === 2 ? hasDiscount ? vw(100, 'desktop') : vw(150, 'desktop') : filterLabelCount === 3 ? hasDiscount ? vw(72, 'desktop') : vw(115, 'desktop') : filterLabelCount === 4 ? hasDiscount ? vw(45, 'desktop') : vw(90, 'desktop') : vw(200, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      max-width: ${({ filterLabelCount, hasDiscount }) => filterLabelCount === 1 ? hasDiscount ? '125px' : '175px' : filterLabelCount === 2 ? hasDiscount ? '100px' : '150px' : filterLabelCount === 3 ? hasDiscount ? '72px' : '115px' : filterLabelCount === 4 ? hasDiscount ? '45px' : '90px' : '200px'};
    }
  }
  .discount {
    background-color: ${({ theme }) => theme.colors.red};
  }

  .filter-label {
    display: flex;
    column-gap: ${vw(5, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      column-gap: ${vw(5, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      column-gap: ${vw(5, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      column-gap: 5px;
    }

    img {
      width: ${vw(24, 'mobile')};
      height: ${vw(24, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        width: ${vw(24, 'nexus7')};
        height: ${vw(24, 'nexus7')};
      }

      ${mq.greaterThan('tablet')} {
        width: ${vw(24, 'desktop')};
        height: ${vw(24, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        width: 24px;
        height: 24px;
      }
    }
  }

  &.narrow-card ~ .product__price:has(.discount)  {
    ${mq.greaterThan('tablet')} {
      margin-top: ${vw(5, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-top: 5px;
    }
  }
`

// const Offer = styled.span`
//   background-color: ${({ theme }) => theme.colors.darkcreme};
//   color: white;
//   border-top-left-radius: 20px;
//   clip-path: polygon(0 0, 0% 100%, 100% 0);
//   width: ${vw(45, 'mobile')};
//   height: ${vw(45, 'mobile')};
//   position: absolute;
//   top: -2px;
//   left: -2px;
//   z-index: 1;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   ${mq.greaterThan('nexus7')} {
//     height: ${vw(60, 'nexus7')};
//     width: ${vw(60, 'nexus7')};
//   }

//   ${mq.greaterThan('tablet')} {
//     height: ${vw(60, 'desktop')};
//     width: ${vw(60, 'desktop')};
//   }

//   ${mq.greaterThan('desktop')} {
//     height: 60px;
//     width: 60px;
//   }

//   span {
//     display: block;
//     ${getP12_1()}
//     text-transform: uppercase;
//     transform: rotate(-45deg) translateY(-80%);

//     ${mq.greaterThan('tablet')} {
//       transform: rotate(-45deg) translateY(-90%);
//     }
//   }
// `

const variants = {
  on_load: {
    opacity: 1,
    transition: {
      ...getTransition(),
      duration: .5,
    }
  },
  on_exit: {
    opacity: 0,
    transition: {
      ...getTransition(),
      duration: .5,
    }
  }
}
